import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'

export default function Home() {
  return (
    <Layout>
      <section>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className="col-sm-12 col-lg-6">
              <p>
                Wait! Give me a call before you think about replacing your front door because of a piece of broken glass. 
              </p>
              <p>
                In most cases, replacing the piece of broken glass can be done at a fraction of the cost of replacing the whole door. If you have a decorative leaded glass panel in your door (as shown in this picture), you actually have a panel of many individual pieces of glass that are wrapped in metal (i.e., the term leaded glass).
              </p>  
              <p>
                These pieces come together and make one decorative panel. Each individual piece of glass, if broken, can be replaced with the same glass. In most cases, front door glass replacement or repair can even be completed onsite at your Austin home in 1 to 2 hours.
              </p>
            </div>
            <div className="col-sm-12 col-lg-3 d-flex justify-content-center mb-5">
              <div>
                <Sidebar />
                <StaticImage 
                  src='../images/IMG6111-225x300.jpg'
                  width = { 225 }
                  alt='logo'
                  placeholder='tracedSVG'
                  quality='40'
                  />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
